import React, { Component } from 'react'


class CoreFeatures extends Component {

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor"> Core Features</span> of our</span>
              NFT Marketplace Development</h3>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  Instant Information
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Trending Collections
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  QR Scanner
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  Features Request
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  Store Front
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Search and Filter 
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  Efficient Listing
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  Minting NFTs
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  Ownership Transfer
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  NFTs for Everyone
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab11')} >
                  Itemize and Trade NFTs
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab12')} >
                  Customer Service
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab14')} >
                  Blogs and Announcements
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab15')} >
                  Different Blockchain Network
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Instant Information
                        </h4>
                        <p className="pharagraph">Get swift notifications for every deposit, withdrawal, buy/sell, market trend, and other platform-related announcement.
                        </p>
                        
                      </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nft/instant-information.png" alt="Instant Information" />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Trending Collections </h4>
                        <p className="pharagraph">Using our NFT Marketplace, users will be able to stay updated with the recent and trending digital collectibles in all the available categories.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nft/trending-collections.png" alt="Trending Collections" />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">QR Scanner</h4>
                        <p className="pharagraph">New users can scan the QR code and install apps on Android & <span className="transNone">i</span>OS. Once installed, free NFT will be rewarded! Using that, new users can list them on the marketplace for bid. This helps the newbies to get an idea about the NFTs.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nft/ownership-transfer.svg" alt="QR Scanner" />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Features Request
                        </h4>
                        <p className="pharagraph">Users can suggest to the admin/site owner what features can be added or improved in the current system. Admin can view them and include them if they’re suitable. Innovative ideas will be welcomed and rewarded.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nft/customer-service.png" alt="Features Request" />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Store Front</h4>
                        <p className="pharagraph">Our software comes with an innovative storefront design to showcase the listed NFTs so that users can easily explore the system.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nft/blogs-and-announcements.png" alt="Store Front" />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Search and Filter</h4>
                        <p className="pharagraph">Our software allows your users to slide through millions of NFTs to filter and search options in order to buy their desired digital collectible.</p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nft/different-blockchain-network.png" alt="Search and Filter" />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Efficient Listing</h4>
                        <p className="pharagraph">Artists can list any of their digital collectibles with files, descriptions, plugin names, and tags.</p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nft/qr-scanner.png" alt="Efficient Listing" />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Minting NFTs</h4>
                        <p className="pharagraph">Creating own NFT is also defined as Minting NFTs and this minting is available to establish digital ownership of the collectibles and artworks by the users.</p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nft/efficient-listing.png" alt="Minting NFTs" />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Ownership Transfer</h4>
                        <p className="pharagraph">Our software is developed in a way where the creators will get their royalty payment support and ownership proof for creating new NFTs.</p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nft/features-request.svg" alt="Ownership Transfer" />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">NFTs for Everyone</h4>
                        <p className="pharagraph">The use cases of our NFT Marketplace development software are open to all industries like arts, sports, music, real estate, digital collectibles, gaming, etc.</p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nft/search-and-filter.png" alt="NFTs for Everyone" />
                    </div>
                  </div>
                </div>
                <div id="tab11" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Itemize and Trade NFTs</h4>
                        <p className="pharagraph">The created or deposited NFTs can be listed in the marketplace for auction or a fixed price for sale with the details of the NFT to sell instantly for a better deal.</p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nft/nft's-for-everyon.png" alt="Itemize and Trade NFTs" />
                    </div>
                  </div>
                </div>
                <div id="tab12" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Customer Service</h4>
                        <p className="pharagraph">Our NFT Marketplace provides live chat support, where your users can initiate a chat with the admin. Even when the admin is not available our chatbots can provide automated responses.</p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nft/store-front.png" alt="Store Front" />
                    </div>
                  </div>
                </div>
                <div id="tab14" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Blogs and Announcements</h4>
                        <p className="pharagraph">Engage your users by posting the latest news, and articles related to crypto markets. Also as an admin, you can notify users of important announcements such as a new feature integration, upcoming server maintenance, and much more.</p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nft/minting-nft's.png" alt="Blogs and Announcements" />
                    </div>
                  </div>
                </div>
                <div id="tab15" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Different Blockchain Network</h4>
                        <p className="pharagraph">Our NFT Marketplace software is capable of supporting dynamic networks like Tron, polygon, Polkadot, etc.</p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nft/itemize-and-trade-nft's.png" alt="Different Blockchain Network" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures