import * as React from "react"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import BannerSection from "../components/nftmarket/Banner"
import Whatis from "../components/nftmarket/Whatis"
import TopFeatures from "../components/nftmarket/TopFeatures"
import HowOur from "../components/nftmarket/HowOur"
import CoreFeatures from "../components/nftmarket/CoreFeatures"
import SecurityofOur from "../components/nftmarket/SecurityofOur"
import UsecaseOf from "../components/nftmarket/UsecaseOf"
import WhyChoose from "../components/nftmarket/WhyChoose"
import Testimonial from "../components/whitelabelpage/Testimonial"
// import Review from "../components/Landing/Review"
import DevApproach from "../components/nftmarket/DevApproach"
import TechStack from "../components/nftmarket/TechStack"
import FaqSection from "../components/nftmarket/FaqSection"
// import PortfolioSection from "../components/nftexchange/Portfolio"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"
import VariousNft from "../components/nftmarket/Variousnft"
import BlockPlat from "../components/nftmarket/BlockPlatform"
import OurService from "../components/nftmarket/OurService"
import DevMethod from "../components/nftmarket/DevMethods"
import MyriadNiches from "../components/nftmarket/MyriadNiches"

// import Header from "../components/header"




const nftmarket = () => (
  <section className="dev-page">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>NFT Marketplace Development Company - Coinsclone</title>
        <meta name="description" content="Coinsclone is a prominent NFT Marketplace development company that provides innovative solutions to launch a secure and profitable NFT Marketplace platform." />
        <meta name="keywords" content="NFT Marketplace development company, NFT Marketplace development, Create NFT Marketplace, Develop NFT Marketplace, Build NFT Marketplace, NFT Marketplace platform development, NFT Trading Platform, NFT Trading Platform Development, NFT website development, NFT website development company" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="NFT Marketplace Development Company - Coinsclone" />
        <meta property="og:description" content="Coinsclone is a prominent NFT Marketplace development company that provides innovative solutions to launch a secure and profitable NFT Marketplace platform." />
        <meta property="og:url" content="https://www.coinsclone.com/nft-marketplace-development/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/nft-marketplace-development.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone is a prominent NFT Marketplace development company that provides innovative solutions to launch a secure and profitable NFT Marketplace platform." />
        <meta name="twitter:title" content="NFT Marketplace Development Company - Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/nft-marketplace-development.png" />
        <link rel="canonical" href="https://www.coinsclone.com/nft-marketplace-development/" />
      </Helmet>
      {/* <Header /> */}
      <BannerSection />
      <div className="breadcrumb mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | NFT Marketplace Development</span>
        </div>
      </div>
      <Whatis />
      <OurService />
      <DevMethod />
      <MyriadNiches />
      <TopFeatures />
      <HowOur />
      <VariousNft />
      <CoreFeatures />
      <SecurityofOur />
      <BlockPlat />
      <UsecaseOf />
      <WhyChoose />
      <Testimonial /> 
      {/* <Review />
      <PortfolioSection />   */}
      <DevApproach /> 
      <TechStack />
      <FaqSection /> 
      <UserFriendly />
    </Layout> 
  </section>
)

export default nftmarket
