import React from 'react'



class OurService extends React.Component {


  render() {

    return (
      <div className='cryptoex padremove'>
      <section className="icon secureof pt-100">
        <div className="container">
          <h2 className="main-heading">Our <span className='bluecolor'>Diverse NFT Marketplace</span> Development Services</h2>
          <p className="text-center">With advanced-level technology, we offer various NFT Marketplace Development services for our clients.</p>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/nft/nft-websites-w.png" alt='NFT Website Development' />
                NFT Marketplace Website Development
                </div>
                <p className="pharagraph">Our expert team specializes in developing high-quality NFT websites that elevate digital asset trading. With top-notch security features, our NFT websites ensure the best protection at every stage, providing a seamless and secure experience for users.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/nft/nft-development-w.png" alt="NFT Development " />
                NFT Development 
                  </div>
                <p className="pharagraph">We specialize in creating NFTs across diverse categories like art, music, sports, and more to meet your business needs. With our team of seasoned NFT developers, we guarantee possible outcomes that match your goals. Connect with us for top-tier NFT solutions that enhance your digital presence.</p>
              </div>
            </div>
            </div>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/nft/nft-smart-contract-development-w.png" alt='NFT Smart Contract Development' />
                NFT Smart Contract Development
                </div>
                <p className="pharagraph">We recognize the critical role that a secure and flawless smart contract plays in NFT development. That’s why every NFT smart contract we create undergoes rigorous design checks, thorough internal audits, and refined optimization strategies to ensure reliability and performance.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/nft/nft-marketplace-app-development-w.png" alt='NFT Marketplace App Development' />
                NFT Marketplace App Development
                </div>
                <p className="pharagraph">To boost NFT user attention and achieve extensive branding, creating NFT Marketplace apps is essential. Coinsclone provides advanced NFT Marketplace applications built on top-tier blockchains, leveraging the latest tools and technologies for optimal performance.</p>
              </div>
            </div>
          </div>
          <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-2 m-auto d-table">Free Trial</a>
          </div>
      </section>
      </div>
    )
  }
}

export default OurService