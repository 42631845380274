import React from "react"

class DevMethod extends React.Component {
  state = {
    modal: false,
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    })
  }

  render() {
    return (
      <div className="pt-100">
      <section className="devmethod gray-bg">
        <div className="container">
          <h2 className="heading-h2 text-center">
          NFT Marketplace Development <span className="bluecolor">Methods We Offer</span>
          </h2>
          <p className="text-center">Coinsclone offers various NFT marketplace development approaches, including customized software for diverse business needs.</p>
          <div className="d-lg-flex">
              <div className="square" >
                <div className='head3'>
                 Development from Scratch
                  <img
                    className="mr-0 ml-1"
                    width={55}
                    height={55}
                    src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/development-from-scratch.png"
                    alt="Development from Scratch"
                  />
                </div>
                <p className="pharagraph">
                The Process of Developing an NFT Marketplace from Scratch is like starting from the base. We provide the entire code without any outsourcing and you can implement your ideas. This development method is a long-term process and requires attention and professional guidance. 
                </p>
              </div>
              <div className="square">
                <div className='head3'>
                 <a href="https://www.coinsclone.com/white-label-nft-marketplace/">White Label NFT Marketplace</a>
                  <img
                    className="mr-0 ml-1"
                    width={55}
                    height={55}
                    src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/hire-our-developers.png"
                    alt="White Label NFT Marketplace"
                  />
                </div>
                <p className="pharagraph">
                This White Label NFT Marketplace Software is a ready-made solution for launching an NFT Platform immediately. Our software is completely bug-free, tested, and doesn't require much coding. Also, our white-label services help in creating your brand without much effort.
                </p>
              </div>
              <div className="square" >
                <div className='head3'>
                 <a href="https://www.coinsclone.com/nft-marketplace-clone-script/">NFT Marketplace Clone script</a>
                  <img
                    className="mr-0 ml-1"
                    width={55}
                    height={55}
                    src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/clone-script.png"
                    alt="NFT Marketplace Clone Script"
                  />
                </div>
                <p className="pharagraph">
                Coinsclone's NFT Marketplace clone script are identical replications of the popular NFT Platforms. Our script is secure and acts as an instant solution to enter the market. Also, it possesses the same functionalities of an existing NFT Marketplace.
                </p>
              </div>
            </div>
          </div>
      </section>
      </div>
    )
  }
}

export default DevMethod
