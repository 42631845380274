import React from "react"
import ButtonComponent from "../ButtonComponent/ButtonComponent"

class VariousNft extends React.Component {

  render() {
    return (
      <div className="cryptoex">
      <section className="remicon pt-100 mb-0">
          <div className="container"> 
            <h2 className="heading-h2 text-center">
            <span className="heading-h3">Ready Made NFT Marketplace Clone Solutions</span> <span className="bluecolor">We Offer</span></h2>
            <p className="text-center">With over 7+ years of experience, we offer NFT Marketplace development services with a commitment to 100% customer satisfaction. Our diversified NFT Marketplace software solutions  </p>
            <div className="d-lg-flex flex-wrap add-one px-0">
              <div className="square" >
                <h3 className="head3">
                 <a href="https://www.coinsclone.com/opensea-clone-script/">OpenSea like NFT Marketplace</a></h3>
                <p className="pharagraph">
                With our agile white label solution, you can launch an advanced-level NFT Marketplace similar to the Opensea platform.
                </p>
              </div>
              <div className="square" >
                <h3 className="head3">
                 <a href="https://www.coinsclone.com/rarible-clone-script/">Rarible-like NFT Marketplace</a></h3>
                <p className="pharagraph">
                The white label solution for Rarible-like platform development helps you deploy your NFT trading platform within 7 to 15 days.
                </p>
              </div>
              <div className="square" >
                <h3 className="head3">
                 <a href="https://www.coinsclone.com/superrare-clone-script/">SuperRare like NFT Marketplace</a></h3>
                <p className="pharagraph">
                The features of the SuperRare NFT Marketplace are replicated in our white label solution and also you can customize it.
                </p>
              </div>
              <div className="square" >
                <h3 className="head3">
                  <a href="https://www.coinsclone.com/foundation-clone-script/">NFT Marketplace like Foundation</a></h3>
                <p className="pharagraph">
                Highly-secured NFT marketplace like Foundation can be replicated with our white label solution. 
                </p>
              </div>
              <div className="square" >
                <h3 className="head3">
                  <a href="https://www.coinsclone.com/enjin-clone-script/">NFT Marketplace like Enjin</a></h3>
                <p className="pharagraph">
                Launch your NFT marketplace like Enjin and integrate your add-on modules in the NFT Marketplace.
                </p>
              </div>  
              <div className="square" >
                <h3 className="head3">
                  <a href="https://www.coinsclone.com/blur-clone-script/">Blur-like NFT Marketplace</a>
                  </h3>
                <p className="pharagraph">
                Feature-rich NFT Marketplace similar to Blur can be developed with our instant NFT Platform solution.
                </p>
              </div>
            </div>
            <div className="mt-3 text-center">
                <ButtonComponent />
            </div>
          </div>
      </section>
      </div>
    )
  }
}

export default VariousNft
